import { projectFirestore, projectFunctions } from "../../../../firebase/config";

const get_client = async(client_num) => {
    try{
        const doc = await projectFirestore.collection('Clients').doc(client_num).get()
        if (!doc.exists) throw new Error(`הלקוח מס: ${client_num} אינו קיים!`)
        return doc.data()
    }catch(err){
        throw new Error(err)
    }
}

const get_clients_invoices = async(client_num) => {
    try{
        const res = await projectFunctions.httpsCallable('get_client_payments')({client_num})
        if(!res.data) throw new Error(`הלקוח מס: ${client_num} אינו קיים!`)
        return res.data
    }catch(err){
        throw new Error(err)
    }
}

export{
    get_client,
    get_clients_invoices
}