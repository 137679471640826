<template>
  <div v-if="user" class="select-client">
    <div ref="wrapper_ref" class="wrapper">
        <h2 style="text-align:center;">סוכן: {{user.name}}, מס: {{user.agent}}</h2>
        <br><br>
        <label style="width:100%;">
            <span>רשום מספר לקוח</span>
            <el-input @keypress.enter="handle_get_client" ref="client_input_ref" v-model="client_number" placeholder="מספר לקוח" />
        </label>
        <br><br>
        <el-button v-if="!is_pending_payment" style="width:100%" @click="handle_get_client"  type="primary">בחר לקוח</el-button>
        <el-button v-if="is_pending_payment" style="width:100%" disable  type="primary">אנא המתן...</el-button>
        <br><br>
        <div v-if="Object.keys(client).length !== 0 && client?.constructor === Object" class="client-details slide-in-fwd-center">
            <span>מספר לקוח: {{client.client_num}}</span>
            <span>שם לקוח: {{client.client_name}}</span>
            <span>ח.פ לקוח: {{client.client_vat}}</span>
            <div @click="handle_change_client" class="float-btn">
                <el-button style="width:100%"   type="primary">החלף לקוח</el-button>
            </div>
            <div class="float-btn continue">
                <el-button @click="handle_continue" style="width:100%"  type="success">המשך</el-button>
            </div>
        </div>
        <br><br>
        <div v-if="credit_card_state" class="payment-form slide-in-fwd-center">
            <div class="credit-image">
                <img :src="credit_card_image" alt="credit card image">
            </div>
            <label>
                <span style="color:red;">*</span> רשימת אימיילים לאישור
                <!-- <el-input v-model="client.payment_name" ref="client_input_ref"  placeholder="שם המשלם" /> -->
                <el-select style="display: block;" v-model="client.contact_selected"   multiple collapse-tags class="m-2" placeholder="בחירת משלם" size="large">
                    <el-option
                        v-for="client in client.contact_data"
                        :key="client.email"
                        :value="client.email"
                    />
                </el-select>
            </label>
            <br>
            <button v-if="!is_pending_payment" @click="handle_move_to_invoices" class="arrow-button">
                <i class="material-icons" style="font-size:30px; margin: 0 5px;">description</i>
                <span>עבור לחשבוניות</span>  
            </button>
            <button v-if="is_pending_payment"  class="arrow-button">
                <i class="material-icons" style="font-size:30px; margin: 0 5px;">description</i>
                <span>אנא המתן...</span>  
            </button>
        </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import {user} from '../../../Methods/Utils'
import {slide_pop_error} from '../../../Methods/Msgs'
import {get_clients_invoices} from './Script'
import credit_card_image from '../../../assets/images/credit_card.png'
import {client_payment_data} from './Store'
export default {
    emits:['move_to_payment'],
    setup(_,{emit}){
        const is_pending_payment = ref(false)
        const wrapper_ref = ref(null)
        const credit_card_state = ref(false)
        const client_input_ref = ref(null)
        const client_number = ref('')
        const client = ref({})

     

        const validation = () => {
            try{
                if(client.value.contact_selected.length == 0) throw new Error('עליך לבחור לפחות אימייל אחד לאישור!')
                return true
            }catch(err){
                throw new Error(err)
            }
        }

        const handle_get_client = async() => {
            try{
                is_pending_payment.value = true
                if(!client_number.value) throw new Error('עליך להזין מס לקוח!')
                client.value = await get_clients_invoices(client_number.value.trim())
                is_pending_payment.value = false
            }catch(err){
                client.value = {}
                is_pending_payment.value = false
                slide_pop_error(err.message)
            }
        }

        const handle_change_client = () => {
            client.value = {}
            credit_card_state.value = false
            client_input_ref.value.focus()
        }

        const handle_continue = () => {
            credit_card_state.value = true
            setTimeout(()=>{
                wrapper_ref.value.scrollTop = wrapper_ref.value.scrollHeight;
            },500)
        }

        const handle_move_to_invoices = async() => {
            client_payment_data.value = client.value
            console.log(client_payment_data.value);
            try{
                if(validation()){
                    is_pending_payment.value = true
                    client_payment_data.value = client.value
                    console.log('client_payment_data:',client_payment_data.value);
                    if(!client_payment_data.value.client_payments_data || client_payment_data.value?.client_payments_data?.length == 0){
                        client_payment_data.value = null
                        throw new Error('לא קיימות חשבוניות')
                    }
                    is_pending_payment.value = false
                    emit('move_to_payment')
                }
            }catch(err){
                is_pending_payment.value = false
                slide_pop_error(err.message)
            }
        }

        onMounted(() => {
            setTimeout(()=>{
                if(client_input_ref.value){
                    client_input_ref.value.focus()
                }
            },500)
        })

        return{
            user,
            client_number,
            client,
            handle_get_client,
            client_input_ref,
            handle_change_client,
            credit_card_image,
            credit_card_state,
            wrapper_ref,
            handle_continue,
            handle_move_to_invoices,
            is_pending_payment,
        }
    }
}
</script>

<style scoped>
    .select-client{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .select-client > .wrapper{
        width: 100%;
        max-width: 500px;
        overflow: hidden;
        overflow-y: auto;
        scroll-behavior: smooth;
    }
    .client-details{
        position: relative;
        width: 100%;
        height: fit-content;
        border-radius: 10px;
        padding: 5px;
        background: var(--secondary);
        display: flex;
        flex-direction: column;
        line-height: 1.5;
    }
    .float-btn{
        position: absolute;
        top: 5px;
        left: 5px;
        width: 100px;
    }
    .float-btn.continue{
        top: unset;
        bottom: 5px;
        left: 5px;
        
    }
    .client-details > span{
        font-size: 25px;
    }

    .payment-form{
        position: relative;
        width: 100%;
        background: var(--secondary);
        display: flex;
        flex-direction: column;
        padding: 5px;
        border-radius: 10px;
    }
   
    .credit-image{
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .credit-image img{
        max-width: 100%;
        max-height: 100%;
    }

    .slika-button {
        position: absolute;
        top: 5px;
        left: 5px;
        display: inline-block;
        padding: 5px 10px;
        border: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;
        background: var(--main);
        color: #fff;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.30);
    }
    .slika-button:hover{
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.70);
    }

    .slika-button .icon {
        display: block;   /* This makes the icon appear on its own line above the text */
        font-size: 24px;  /* Adjust size of the icon as required */
        margin-bottom: 5px;  /* Space between the icon and the text */
    }

    .slika-button .text {
        display: block;
    }

    .arrow-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        border: none;
        background-color: #007BFF;
        color: white;
        font-size: 20px;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

    .arrow-button:hover {
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
    }

    .slide-in-fwd-center {
	    -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	    animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    @-webkit-keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
                transform: translateZ(-1400px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
                transform: translateZ(-1400px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        opacity: 1;
    }
    }


</style>